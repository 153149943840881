import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper, Snackbar, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { getEstadoAlbaran, updateEstadosAlbaran, deleteEstadoAlbaran } from '../service/apiEstadosAlbaran';  
import { FaEdit, FaTrashAlt } from 'react-icons/fa';

const UpdateEstados = () => {

    const [estados, setEstados] = useState([]);  
    const [showSnackbar, setShowSnackbar] = useState(false); 
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [openDialog, setOpenDialog] = useState(false);  
    const [selectedEstado, setSelectedEstado] = useState({ 
        est_alb_nombre: '', 
        est_alb_id: 0
    });

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);  // Dialog de confirmación de eliminación
    const [estadoAEliminar, setEstadoAEliminar] = useState(null); // Estado que se va a eliminar


    // Obtener los estados de la base de datos
    useEffect(() => {
        const fetchEstados = async () => {
            try {
                const response = await getEstadoAlbaran();
                setEstados(response);  // Asumimos que la respuesta es un array de objetos de estado
            } catch (error) {
                console.error('Error al obtener los estados:', error);
            }
        };
        fetchEstados();
    }, []);

    // Manejar el clic en el botón de editar
    const handleEdit = (estado) => {
        setSelectedEstado(estado);
        setOpenDialog(true);
    };

    // Manejar el clic en el botón de eliminar
    const handleDelete = (estado) => {
        setEstadoAEliminar(estado);  // Guardamos el estado a eliminar
        setOpenConfirmDialog(true);  // Abrimos el dialog de confirmación
    };

    // Confirmar la eliminación
    const confirmDelete = async () => {
        try {
            const response = await deleteEstadoAlbaran(estadoAEliminar.est_alb_id);
            if (response) {
                setSnackbarMessage('Estado eliminado correctamente');
                setShowSnackbar(true);
                setOpenConfirmDialog(false);
                // Volver a cargar los estados
                const updatedEstados = await getEstadoAlbaran();
                setEstados(updatedEstados);
            }
        } catch (error) {
            console.error('Error al eliminar estado:', error);
            setSnackbarMessage('Error al eliminar el estado');
            setShowSnackbar(true);
            setOpenConfirmDialog(false);
        }
    };

    // Cancelar la eliminación
    const cancelDelete = () => {
        setOpenConfirmDialog(false); // Solo cerramos el dialogo de confirmación
    };

    // Manejar el envío del formulario de edición
    const handleSubmitEdit = async () => {
        try {
            const dataToSend = { est_alb_nombre: selectedEstado.est_alb_nombre };

            const response = await updateEstadosAlbaran(selectedEstado.est_alb_id, dataToSend);
            if (response) {
                setSnackbarMessage('Estado actualizado correctamente');
                setShowSnackbar(true);
                setOpenDialog(false);
                // Volver a cargar los estados
                const updatedEstados = await getEstadoAlbaran();
                setEstados(updatedEstados);
            }
        } catch (error) {
            console.error('Error al actualizar estado:', error);
            setSnackbarMessage('Error al actualizar el estado');
            setShowSnackbar(true);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedEstado(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <TableContainer 
                component={Paper} 
                sx={{ 
                    maxWidth: 900, 
                    width: '100%', 
                    boxShadow: 3,
                    maxHeight: '700px',  
                    overflowY: 'auto' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Nombre</strong></TableCell>
                            <TableCell align="center"><strong>Acciones</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {estados.map((estado) => (
                            <TableRow key={estado.est_alb_id}>
                                <TableCell>{estado.est_alb_nombre}</TableCell>
                                <TableCell align="center">
                                    {/* Botón de Editar */}
                                    <Button
                                        onClick={() => handleEdit(estado)}
                                        variant="outlined"
                                        color="primary"
                                        sx={{ marginRight: 1, padding: '6px' }}
                                    >
                                        <FaEdit />
                                    </Button>
                                    {/* Botón de Eliminar */}
                                    <Button
                                        onClick={() => handleDelete(estado)}  // Pasamos el estado a eliminar
                                        variant="outlined"
                                        color="error"
                                        sx={{ padding: '6px' }}
                                    >
                                        <FaTrashAlt />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Snackbar para mensajes de éxito/error */}
            <Snackbar
                open={showSnackbar}
                autoHideDuration={6000}
                onClose={() => setShowSnackbar(false)}
                message={snackbarMessage}
            />

            {/* Diálogo para editar estado */}
            <Dialog 
                open={openDialog} 
                onClose={() => setOpenDialog(false)} 
                maxWidth="md"  
                fullWidth  
            >
                <DialogTitle sx={{ marginBottom: '20px' }}>
                    Editar Estado
                </DialogTitle>
                <DialogContent style={{ paddingTop: '10px' }}>
                    <TextField
                        label="Nombre del Estado"
                        style={{ marginBottom: '10px', paddingTop: '0' }} 
                        variant="outlined"
                        fullWidth  
                        name="est_alb_nombre"
                        value={selectedEstado.est_alb_nombre}
                        onChange={handleInputChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="error">
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmitEdit} color="primary">
                        Guardar Cambios
                    </Button>
                </DialogActions>
            </Dialog>


            {/* Diálogo de confirmación de eliminación */}
            <Dialog 
                open={openConfirmDialog} 
                onClose={cancelDelete} 
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>¿Estás seguro de que quieres eliminar este estado?</DialogTitle>
                <DialogActions>
                    <Button onClick={cancelDelete} color="secondary">
                        Cancelar
                    </Button>
                    <Button onClick={confirmDelete} color="error">
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};

export default UpdateEstados;
