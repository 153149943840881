import React, { useState, useEffect } from 'react';
import { Select, CircularProgress, Autocomplete, FormControl, InputLabel, MenuItem } from '@mui/material';
import axios from 'axios';
import { getEstadoAlbaran } from '../service/apiEstadosAlbaran';


const EstadosSelect = ({onEstadoChange, codCliente}) =>{

    const [estados, setEstados] = useState([]);
    const [selectedEstado, setSelectedEstado] = useState('');
    const [loading, setLoading] = useState(false);


    const fetchEstadosAlbaran = async () => {
        try{    
            setLoading(true);
            const response = await getEstadoAlbaran();
            setEstados(response);

            setLoading(false);
        }catch(error){
            console.error('Error al obtener los estados de Albaran ', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEstadosAlbaran();
    }, []);


    const handleChange = (event) =>{
        setSelectedEstado(event.target.value);
        const [estadoSeleccionado] = estados.filter(estado => estado.est_alb_id === event.target.value)
        const nombreEstado = estadoSeleccionado.est_alb_nombre
        if (typeof onEstadoChange === 'function') {
            codCliente(event.target.value);
            onEstadoChange(nombreEstado);
        }
    }

    return(
        <FormControl fullWidth>
            <InputLabel id="estado-label">Selecciona Estado Albarán </InputLabel>
            {loading ? (
                <CircularProgress/>
            ) : ( 
                <Select
                    labelId= "estado-label"
                    value= {selectedEstado}
                    label = "Selecciona Estado Alabarán"
                    onChange= {handleChange}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 200, // Altura máxima del menú desplegable
                                overflowY: 'auto', // Habilitar desplazamiento vertical
                            },
                        },
                    }}  
                >
                {estados.map((estado) => (
                    <MenuItem key={estado.est_alb_id} value={estado.est_alb_id}>
                        {estado.est_alb_nombre}
                    </MenuItem>
                ))}
                </Select>
            )}
        </FormControl>
    )

}

export {EstadosSelect}