import axios from 'axios';
import API_URL from  './config';

export const getResiduos = async () => {

    try{
        const response = await axios.get(`${API_URL}/residuos/getResiduos`);
        return response.data
    } catch(error){
        console.error('Error obteniendo residuos', error );
        return [];
    }
}


export const getResiduoById = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/residuos/getResiduo/${id}`);
        return response.data

    } catch (error) {
        console.error(`Error obteniendo residuo con ID ${id}`, error);
        return null; // o [] si quieres devolver un array vacío
    }
}

export const getResiduoByDescrip = async (descript) => {

    try {
        const response = await axios.get(`${API_URL}/residuos/getResiduo/${descript}`);
        return response.data;
    } catch (error) {
        console.error(`Error obteniendo residuo con descripcion "${descript}"`, error);
        return null; // o [] si quieres devolver un array vacío
    }
};


export const getResiduoByIdAndAlmacen = async (almacenId, residuoId) => {
    try {

        const response = await axios.get(`${API_URL}/residuos/getResiduo/${almacenId}/${residuoId}`);      
        return response.data;
    } catch (error) {
        console.error('Error al obtener el residuo por ID y almacén', error);
        return null;
    }
};

export const addResiduo = async (residuoData) => {
    try {
        // Realizamos la solicitud POST para agregar un residuo
        const response = await axios.post(`${API_URL}/residuos/addResiduos`, residuoData, {
            headers: {
                'Content-Type': 'application/json',  // Aseguramos que el cuerpo sea JSON
            }
        });

        // Si la solicitud es exitosa, devolvemos la data
        return response.data;
    } catch (error) {
        // Si hay un error, lo capturamos y mostramos en consola
        console.error('Error al agregar el residuo', error);
        return null;
    }
};

export const addPrecioResiduo = async(precioAlmacen) => {
    try{
        const response = await axios.post(`${API_URL}/residuos/addPrecioResiduo`, precioAlmacen, {
            headers: {
                'Content-Type': 'application/json',  // Aseguramos que el cuerpo sea JSON
            }
        });
        return response;

    }catch(error){
        console.error('Error al agregar el residuo', error);
        return null;
    }
    
}


export const updateResiduos = async(id, residuoData) => {
    if(typeof residuoData != 'object'){
        console.error('El residuoData debe ser un objeto', residuoData); 
        return null;
    }

    try {
        const response = await axios.put(
            `${API_URL}/residuos/editarResiduo/${id}`,
            residuoData, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        );
        return response.data; 
    } catch (error) {
        console.error('Error al actualizar el residuo', error);
        return null; 
    }
}

export const deleteResiduo = async(id) => {
    try {
        const response = await axios.delete(
            `${API_URL}/residuos/deleteResiduo/${id}`
        );
        return response.data; 
    } catch (error) {
        console.error('Error al eliminar resiudo',id); 
        return null; 
    }
}