import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Snackbar } from '@mui/material';
import { addEstadosAlbaran } from '../service/apiEstadosAlbaran';  // Asegúrate de que esta función esté implementada en el servicio
import Modal from "../modal/modal";  // Usamos un Modal para mostrar el mensaje si lo prefieres

const CrearEstado = () => {
    const [formData, setFormData] = useState({
        est_alb_nombre: "",  // Nombre del estado
    });
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Función para manejar el envío del formulario
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validación básica
        if (formData.est_alb_nombre.trim() === '') {
            setSnackbarMessage('El nombre del estado no puede estar vacío');
            setOpenSnackbar(true);
            return;
        }

        // Llamada a la API para agregar el estado
        try {
            const response = await addEstadosAlbaran(formData);  // Asegúrate de que esta función esté bien implementada
            if (response) {
                setModalMessage(`Estado "${formData.est_alb_nombre}" creado con éxito`);
                setShowModal(true);
                setFormData({ est_alb_nombre: "" });  // Limpiar el campo del formulario
            } else {
                setSnackbarMessage('Hubo un error al crear el estado');
                setOpenSnackbar(true);
            }
        } catch (error) {
            console.error('Error al crear el estado:', error);
            setSnackbarMessage('Hubo un error al crear el estado');
            setOpenSnackbar(true);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalMessage("");
    };

    return (
        <Box
            sx={{
                maxWidth: '400px',
                margin: '20px auto',
                padding: '20px',
                borderRadius: '10px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#ffffff',
            }}
        >
            <Typography variant="h5" component="h2" gutterBottom>
                Crear Estado
            </Typography>

            <form onSubmit={handleSubmit}>
                <TextField
                    label="Nombre del Estado"
                    variant="outlined"
                    fullWidth
                    name="est_alb_nombre"  // Asegúrate de que el name coincida con el estado
                    value={formData.est_alb_nombre}
                    onChange={handleChange}
                    margin="normal"
                    required
                />

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ marginTop: '10px' }}
                >
                    Crear Estado
                </Button>
            </form>

            {/* Mostrar Snackbar para mensajes cortos */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
                message={snackbarMessage}
            />

            {/* Modal para mostrar mensajes de éxito */}
            {showModal && (
                <Modal
                    message={modalMessage}
                    onClose={handleCloseModal}
                />
            )}
        </Box>
    );
};

export default CrearEstado;
