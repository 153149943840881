import React, { useState, useEffect } from 'react';
import { 
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper, Snackbar, 
    Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, Box, Grid, IconButton 
} from '@mui/material';
import { getAlmacenes, updateAlmacen, deleteAlmacen } from '../service/apiAlmacenes';  
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { FaCaretUp, FaCaretDown} from 'react-icons/fa';

import CrearAlmacen from './createAlmacen';

const UpdateAlmacenes = () => {
    const [almacenes, setAlmacenes] = useState([]);
    const [selectedAlmacen, setSelectedAlmacen] = useState({
        alm_id: 0, 
        alm_nombre: '' 
    });
    const [showSnackbar, setShowSnackbar] = useState(false); 
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [openDialog, setOpenDialog] = useState(false);  
    const [openDialogAlm, setOpenDialogAlm] = useState(false);  
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);  
    const [almacenEliminar, setAlmacenEliminar] = useState(null); 
    const [sortDirection, setSortDirection] = useState('asc');  // Estado para la dirección de ordenación

    useEffect(() => {
        const fetchAlmacenes = async () => {
            try {
                const response = await getAlmacenes();
                setAlmacenes(response);
            } catch (error) {
                console.error('Error al obtener los almacenes', error);
            }
        };
        fetchAlmacenes();
    }, []);

    const handleSort = () => {
        const sortedAlmacenes = [...almacenes].sort((a, b) => {
            // Normalizar los nombres y hacer comparación case-insensitive
            const nombreA = a.alm_nombre.trim().toLowerCase();
            const nombreB = b.alm_nombre.trim().toLowerCase();
    
            if (nombreA < nombreB) return sortDirection === 'asc' ? -1 : 1;
            if (nombreA > nombreB) return sortDirection === 'asc' ? 1 : -1;
            return 0;  // Si son iguales
        });
        setAlmacenes(sortedAlmacenes);
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc'); // Cambiar la dirección de ordenación
    };

    const handleEditAlmacen = async () => {
        try {
            const dataToSend = { alm_nombre: selectedAlmacen.alm_nombre };
            const response = await updateAlmacen(selectedAlmacen.alm_id, dataToSend);
            if (response) {
                setSnackbarMessage('Almacen actualizado correctamente');
                setShowSnackbar(true);
                setOpenDialog(false);
                const updatedAlmacenes = await getAlmacenes();
                setAlmacenes(updatedAlmacenes);
            }
        } catch (error) {
            console.error('Error al actualizar almacen:', error);
            setSnackbarMessage('Error al actualizar el almacen');
            setShowSnackbar(true);
        }
    };

    const handleEdit = (almacen) => {
        setSelectedAlmacen(almacen);
        setOpenDialog(true); 
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedAlmacen(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const confirmDelete = async () => {
        try {
            const response = await deleteAlmacen(almacenEliminar.alm_id); 
            if (response) {
                setSnackbarMessage('Almacen eliminado correctamente');
                setShowSnackbar(true);
                setOpenConfirmDialog(false);
                const updatedAlmacenes = await getAlmacenes();
                setAlmacenes(updatedAlmacenes);
            }
        } catch (error) {
            console.error('Error al eliminar almacen:', error);
            setSnackbarMessage('Error al eliminar el almacen');
            setShowSnackbar(true);
            setOpenConfirmDialog(false);
        }
    };

    const cancelDelete = () => {
        setOpenConfirmDialog(false); 
    };

    const handleDelete = (almacen) => {
        setAlmacenEliminar(almacen);
        setOpenConfirmDialog(true); 
    };
    
    // Abrir ventanas de dialogo para crear almacen 
    const handleOpenDialog = () => {
        setOpenDialogAlm(true);
    };

    const handleCloseDialog = () => {
        setOpenDialogAlm(false);
    };

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '20px auto' }}>
                {/* Contenedor para la tabla de almacenes */}
                <Box sx={{ flex: 1, maxWidth: '75%' }}>
                    <TableContainer 
                        component={Paper} 
                        sx={{ 
                            maxWidth: 900, 
                            width: '100%', 
                            boxShadow: 3, 
                            maxHeight: '700px', 
                            overflowY: 'auto',
                            borderRadius: '12px', 
                            margin:'auto'
                        }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell 
                                        sx={{ fontWeight: 'bold', color: '#000', fontSize:'18px'}}
                                        onClick={handleSort}  // Añadir el evento de clic
                                    >
                                        <strong>Almacen</strong> 
                                        {sortDirection === 'asc' ? <FaCaretUp /> : <FaCaretDown />}  {/* Mostrar flecha indicando la dirección */}
                                    </TableCell>
                                    <TableCell align="center" sx={{ fontWeight: 'bold', color: '#000', fontSize:'18px' }}><strong>Acciones</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {almacenes.map((almacen) => (
                                    <TableRow key={almacen.alm_id} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f5f5f5' }}}>
                                        <TableCell>{almacen.alm_nombre}</TableCell>
                                        <TableCell align="center">
                                            {/* Botón de Editar */}
                                            <IconButton
                                                onClick={() => handleEdit(almacen)}
                                                color="primary"
                                                sx={{ fontSize: '1.3em'}}
                                            >
                                                <FaEdit />
                                            </IconButton>
                                            {/* Botón de Eliminar */}
                                            <IconButton
                                                onClick={() => handleDelete(almacen)} 
                                                color="error"
                                                sx={{ fontSize: '1.3em'}}
                                            >
                                                <FaTrashAlt />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                {/* Menú de Acciones (Parte derecha) */}
                <Box sx={{ width: '25%', padding: '20px', borderLeft: '2px solid #eee' }}>
                    <Typography variant="h6" sx={{ marginBottom: 2 }}>Acciones del Almacen</Typography>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        sx={{ width: '100%', marginBottom: '10px' }}
                        onClick={handleOpenDialog}
                    >
                        Crear Almacen
                    </Button>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        sx={{ width: '100%', marginBottom: '10px' }}
                        onClick={() => alert('Pendiente modulo para exportar almacenes')}
                    >
                        Importar Almacenes 
                    </Button>
                </Box>
            </Box>

            {/* Snackbar para mensajes */}
            <Snackbar
                open={showSnackbar}
                autoHideDuration={6000}
                onClose={() => setShowSnackbar(false)}
                message={snackbarMessage}
                sx={{ bottom: 100 }} 
            />

            {/* Ventana de diálogo para editar almacen */}
            <Dialog 
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                maxWidth="md"
                fullWidth
                sx={{ borderRadius: '12px' }}
            >
                <DialogTitle sx={{ marginBottom: '20px', fontWeight: 'bold' }}>
                    Editar Almacen
                </DialogTitle>
                <DialogContent style={{ paddingTop: "10px" }}>
                    <TextField
                        label="Nombre del Almacen"
                        variant="outlined"
                        fullWidth
                        name="alm_nombre"
                        value={selectedAlmacen.alm_nombre || ''}
                        onChange={handleInputChange}
                        sx={{ marginBottom: '20px' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="error" sx={{ textTransform: 'none' }}>
                        Cancelar
                    </Button>
                    <Button onClick={handleEditAlmacen} color="primary" sx={{ textTransform: 'none' }}>
                        Guardar Cambios
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Ventana de diálogo para confirmar eliminación */}
            <Dialog
                open={openConfirmDialog} 
                onClose={cancelDelete} 
                maxWidth="sm"
                fullWidth
                sx={{ borderRadius: '12px' }}
            >
                <DialogTitle>
                    ¿Estás seguro de eliminar el almacen:
                    <Typography sx={{ fontWeight: 'bold', color: '#d32f2f' }}>
                        {almacenEliminar ? almacenEliminar.alm_nombre : ''}
                    </Typography>?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={cancelDelete} color="secondary" sx={{ textTransform: 'none' }}>
                        Cancelar
                    </Button>
                    <Button onClick={confirmDelete} color="error" sx={{ textTransform: 'none' }}>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Aquí pasamos correctamente las funciones */}
            <CrearAlmacen
                openDialog={openDialogAlm}
                onClose={handleCloseDialog}
                setSnackbarMessage={setSnackbarMessage}
                setOpenSnackbar={setShowSnackbar}
                setAlmacenes={setAlmacenes}
            />
        </>
    );
};

export default UpdateAlmacenes;
