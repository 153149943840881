import React, { useState } from "react";
import { TextField, Button, Box, Typography, Grid, Container } from "@mui/material";
import { addProveedor } from "../service/apiProveedores";  // Asumiendo que tienes un servicio similar para proveedores
import Modal from "../modal/modal";  // Usamos el mismo modal para mostrar el mensaje

const CrearProveedor = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [formData, setFormData] = useState({
    prov_nombre: "",
    prov_direccion: "",
    prov_codigo_postal: 0,
    prov_poblacion: "",
    prov_provincia: "",
    prov_nif: "",
    prov_telefono: 0,
    prov_email: "",
    prov_cta_bancaria: "",
    prov_num_tarjeta_ecopay: "",
    prov_total_op_anual: 0,
    prov_matricula: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await addProveedor(formData);  // Suponiendo que tienes la función addProveedor en tu servicio
    if (response) {
      setShowModal(true);
      setModalMessage(`Proveedor agregado con éxito`);
    } else {
      setShowModal(true);
      setModalMessage(`Error al agregar el proveedor`);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalMessage("");
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ marginTop: 5, padding: 3, backgroundColor: "#fff", borderRadius: 2, boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',}}>
        <Typography variant="h4" gutterBottom align="center">
          Crear Proveedor
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nombre del Proveedor"
                variant="outlined"
                name="prov_nombre"
                value={formData.prov_nombre}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Dirección"
                variant="outlined"
                name="prov_direccion"
                value={formData.prov_direccion}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Código Postal"
                variant="outlined"
                name="prov_codigo_postal"
                value={formData.prov_codigo_postal}
                onChange={handleChange}
                required
                type="number"
                inputProps={{ min: 0 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Población"
                variant="outlined"
                name="prov_poblacion"
                value={formData.prov_poblacion}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Provincia"
                variant="outlined"
                name="prov_provincia"
                value={formData.prov_provincia}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="NIF"
                variant="outlined"
                name="prov_nif"
                value={formData.prov_nif}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Teléfono"
                variant="outlined"
                name="prov_telefono"
                value={formData.prov_telefono}
                onChange={handleChange}
                required
                type="number"
                inputProps={{ min: 0 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                name="prov_email"
                value={formData.prov_email}
                onChange={handleChange}
                required
                type="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Cuenta Bancaria"
                variant="outlined"
                name="prov_cta_bancaria"
                value={formData.prov_cta_bancaria}
                onChange={handleChange}
                
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Número Tarjeta Ecopay"
                variant="outlined"
                name="prov_num_tarjeta_ecopay"
                value={formData.prov_num_tarjeta_ecopay}
                onChange={handleChange}
                type="number"
                inputProps={{ min: 0 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Total Operaciones Anuales"
                variant="outlined"
                name="prov_total_op_anual"
                value={formData.prov_total_op_anual}
                onChange={handleChange}
                type="number"
                inputProps={{ min: 0 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Matrícula"
                variant="outlined"
                name="prov_matricula"
                value={formData.prov_matricula}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" fullWidth type="submit">
                Crear Proveedor
              </Button>
              {showModal && (
                <Modal
                  message={modalMessage}
                  onClose={handleCloseModal}
                />
              )}
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default CrearProveedor;
