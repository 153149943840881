import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Snackbar, MenuItem, Select, FormControl, InputLabel } from '@mui/material'; 
import { getAlmacenes } from '../service/apiAlmacenes';  // Función para obtener los almacenes
import { getResiduos } from '../service/apiResiduos';  // Función para obtener los residuos
import { addPrecioResiduo } from '../service/apiResiduos';
import Modal from "../modal/modal";  // Modal para mostrar el mensaje si lo prefieres

const CreatePrecioResiduo = () => {
    const [almacenes, setAlmacenes] = useState([]);  // Lista de almacenes
    const [almacen, setAlmacen] = useState([]);
    const [residuo, setResiduo] = useState([]); 

    const [residuos, setResiduos] = useState([]);  // Lista de residuos
    const [formData, setFormData] = useState({
        pr_alm_id: "",  // ID del almacén, inicializado como una cadena vacía
        pr_res_id: "",  // ID del residuo, inicializado como una cadena vacía
        pr_precio: "",      // Precio para ese residuo en ese almacén
    });
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    // Obtener almacenes y residuos al montar el componente
    useEffect(() => {
        const fetchData = async () => {
            const almacenesResponse = await getAlmacenes();
            const residuosResponse = await getResiduos();
            if (almacenesResponse && residuosResponse) {
                setAlmacenes(almacenesResponse);
                setResiduos(residuosResponse);
            }
        };

        fetchData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,  
        }));

    };

    // Función para manejar el envío del formulario
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validación básica
        if (!formData.pr_alm_id || !formData.pr_res_id || !formData.pr_precio) {
            setSnackbarMessage('Todos los campos son obligatorios');
            setOpenSnackbar(true);
            return;
        }

        // Llamada a la API para agregar el precio de residuo
        try {
            const response = await addPrecioResiduo(formData);  // Llamada a la API

            if (response.status == 200) {
                setModalMessage(`Precio actualizado con éxito`);
                setShowModal(true);
                setFormData({ pr_alm_id: "", pr_res_id: "", pr_precio: "" });  // Limpiar formulario
            } else if (response.status == 201) {
                setModalMessage(`Precio creado con éxito`);
                setShowModal(true);
                setFormData({ pr_alm_id: "", pr_res_id: "", pr_precio: "" });  // Limpiar formulario
            }else {
                setSnackbarMessage('Hubo un error al crear el precio');
                setOpenSnackbar(true);
            }
        } catch (error) {
            console.error('Error al crear el precio del residuo:', error);
            setSnackbarMessage('Hubo un error al crear el precio');
            setOpenSnackbar(true);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalMessage("");
    };

    useEffect(() => {
    }, [formData]);

    return (
        <Box
            sx={{
                maxWidth: '600px',
                margin: '20px auto',
                padding: '20px',
                borderRadius: '10px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#ffffff',
            }}
        >
            <Typography variant="h5" component="h2" gutterBottom>
                Crear Precio de Residuo por Almacén
            </Typography>

            <form onSubmit={handleSubmit}>
            <FormControl fullWidth margin="normal" required>
                <InputLabel>Seleccionar Almacén</InputLabel>
                <Select
                    name="pr_alm_id"
                    value={formData.pr_alm_id || ""}
                    onChange={handleChange}
                    label="Seleccionar Almacén"
                >
                    {almacenes.map((almacen) => (
                        <MenuItem key={almacen.alm_id} value={almacen.alm_id}>
                            {almacen.alm_nombre}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl fullWidth margin="normal" required>
                <InputLabel>Seleccionar Residuo</InputLabel>
                <Select
                    name="pr_res_id"
                    value={formData.pr_res_id || ""}
                    onChange={handleChange}
                    label="Seleccionar Residuo"
                >
                    {residuos.map((residuo, index) => (
                        <MenuItem key={residuo.res_id || index} value={residuo.res_id}>
                            {residuo.res_nombre}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>


                <TextField
                    label="Precio del Residuo"
                    variant="outlined"
                    fullWidth
                    name="pr_precio"
                    value={formData.pr_precio}
                    onChange={handleChange}
                    margin="normal"
                    type="number"
                    required
                />

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ marginTop: '10px' }}
                >
                    Crear Precio
                </Button>
            </form>

            {/* Mostrar Snackbar para mensajes cortos */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
                message={snackbarMessage}
            />

            {/* Modal para mostrar mensajes de éxito */}
            {showModal && (
                <Modal
                    message={modalMessage}
                    onClose={handleCloseModal}
                />
            )}
        </Box>
    );
};

export default CreatePrecioResiduo;
