import axios from 'axios';
import API_URL from  './config';


export const addAlbaran = async (albaranData) => {
    try {
        const response = await axios.post(`${API_URL}/albaran/addAlbaran`, albaranData);
        return response.data.insertId; // Devuelve la respuesta del servidor
    } catch (error) {
        console.error("Error al crear el albarán:", error);
        throw error; // Propaga el error para manejarlo en el componente
    }
};

export const addLinea = async (lineaData) => {
    try {
        const response = await axios.post(`${API_URL}/lineaAlbaran/addLineaAlbaran`, lineaData);
        return response.data.insertId; // Devuelve la respuesta del servidor
    } catch (error) {
        console.error("Error al agregar una línea:", error);
        throw error; // Propaga el error para manejarlo en el componente
    }
};