import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  FormControl,
  InputAdornment,
  Paper,
  Autocomplete,
} from "@mui/material";
import {
  getResiduoById,
  getResiduos,
  getResiduoByIdAndAlmacen,
} from "../service/apiResiduos";
import Modal from "../modal/modal";

function ResiduosTable({ almacenId, rowsP, onRowsUpdate }) {
  const [residuos, setResiduos] = useState([]);
  const [rows, setRows] = useState([
    {
      id: 1,
      articulo: "",
      descripcion: "",
      cantidad: 0,
      precio: 0,
      subtotal: 0,
      impuesto: 0,
      total: 0,
      residuos: [],
    },
  ]);
  const [residuosOptions, setResiduosOptions] = useState([]);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [editableRow, setEditableRow] = React.useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  useEffect(() => {
    const fetchResiduosForAlmacen = async () => {
      if (!almacenId) {
        //alert("Debe seleccionar un almacén antes de continuar.");
        return;
      }

      const updatedRows = [...rows];
      for (let index = 0; index < updatedRows.length; index++) {
        const currentRow = updatedRows[index];

        // Si el campo "articulo" está vacío, resetea la fila
        if (!currentRow.articulo) {
          updatedRows[index] = {
            ...currentRow,
            articulo: "",
            descripcion: "",
            precio: 0,
            impuesto: 0,
            cantidad: 0,
            subtotal: 0,
            total: 0,
          };
        } else {
          //console.log('aqui entra cuando cambia almacen con un codigo de articulo')
          try {
            const [residuoData] = await getResiduoByIdAndAlmacen(
              almacenId.id,
              currentRow.articulo
            );
            if (residuoData) {
              updatedRows[index] = {
                ...currentRow,
                precio: residuoData.precio || 0,
                impuesto: residuoData.res_iva || 0,
                subtotal: currentRow.cantidad * residuoData.precio,
                total: currentRow.cantidad * residuoData.precio,
              };
            } else {
              // Resetea la fila si no hay información del residuo en el almacén
              updatedRows[index] = {
                ...updatedRows[index],
                articulo: "",
                descripcion: "",
                precio: 0,
                impuesto: 0,
                cantidad: 0,
                subtotal: 0,
                total: 0,
              };
              setShowModal(true);
              setModalMessage(
                `No se encontró precio para el artículo ${currentRow.articulo} en el almacén seleccionado.`
              );
            }
          } catch (error) {
            console.error(
              `Error al obtener residuo ${currentRow.articulo}:`,
              error
            );
            alert("Ocurrió un error al actualizar los datos de los residuos.");
          }
        }
      }

      setRows(updatedRows);
    };

    fetchResiduosForAlmacen();
  }, [almacenId]);

  const handleInputChange = async (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;

    if (field === "articulo" && value) {
      if (!almacenId) {
        setShowModal(true);
        setModalMessage(
          `Debe Seleccionar un almacen para visualizar residuos`
        );
        updatedRows[index] = {
          articulo: "",
          descripcion: "",
          precio: 0,
          impuesto: 0,
          cantidad: 0,
          subtotal: 0,
          total: 0,
        };
        return;
      } else {

        const [residuoData] = await getResiduoByIdAndAlmacen(
          almacenId.id,
          value
        );
        if (residuoData) {
          updatedRows[index] = {
            ...updatedRows[index],
            articulo: residuoData.res_id || value,
            descripcion: residuoData.res_descripcion,
            precio: residuoData.precio || 0,
            impuesto: residuoData.res_iva || 0,
            cantidad: 0,
            subtotal: 0,
            total: 0,
          };
        } else if(value.length > 2 ){
          updatedRows[index] = {
            ...updatedRows[index],
            articulo: "",
            descripcion: "",
            precio: 0,
            impuesto: 0,
            cantidad: 0,
            subtotal: 0,
            total: 0,
          };
          setShowModal(true);
          setModalMessage("No hay arcitulo con el id", value);
        }

      }
    } else if (field === "articulo" && !value) {
      updatedRows[index] = {
        ...updatedRows[index],
        articulo: "",
        descripcion: "",
        precio: 0,
        impuesto: 0,
        cantidad: 0,
        subtotal: 0,
        total: 0,
      };
    } else if (field === "descripcion" && value) {
      if (!almacenId) {
        setShowModal(true);
        setModalMessage(
          `Debe seleccionar un almacén para visualizar residuos.`
        );
        return;
      }
  
      const residuoData = await getResiduos();
      const filteredResiduos = residuoData.filter((residuo) =>
        residuo.res_descripcion.toLowerCase().includes(value.toLowerCase())
      );
      setResiduosOptions(filteredResiduos);
      const IDdescripResiduos = filteredResiduos[0]?.res_id || null;
  
    

      if (IDdescripResiduos) {
        const residuoDataXPrecio = await getResiduoByIdAndAlmacen(
          almacenId.id,
          IDdescripResiduos
        );
  
        if (filteredResiduos.length === 1 && residuoDataXPrecio.length === 1) {
  
          const selectedResiduo = residuoDataXPrecio[0];
          // console.log('residuoDataXPrecio', residuoDataXPrecio);
          // console.log('selectedResiduo', selectedResiduo);
          // console.log('selectedResiduo.res_id', selectedResiduo.res_id)
          // console.log('index', index)

          if (selectedResiduo){
            updatedRows[index] = {
              articulo: selectedResiduo.res_id,
              descripcion: selectedResiduo.res_descripcion,
              precio: selectedResiduo.precio || 0,
              impuesto: selectedResiduo.res_iva || 0,
              cantidad: 0,
              subtotal: 0,
              total: 0,
            };
            setRows(updatedRows);
          }

          
        }
      }
    } else if (field === "descripcion" && !value) {
      updatedRows[index] = {
        ...updatedRows[index],
        articulo: "",
        descripcion: "",
        precio: 0,
        impuesto: 0,
        cantidad: 0,
        subtotal: 0,
        total: 0,
      };
    } else if (field === "cantidad" && value) {
      const regex = /^\d*(\.\d*)?$/;
        if(!regex.test(value)){
          setShowModal(true);
          setModalMessage( `solo puede agregar numeros y para agregar decimales, agrege un punto (.)`); 
          updatedRows[index].cantidad = '';
          return
        }else{
          const valorCantidad = parseFloat(value);
          if (updatedRows) {
            const impuestoIVADesc = valorCantidad * parseFloat(updatedRows[index].precio) * (parseFloat(updatedRows[index].impuesto) / 100);
            const subtotal = valorCantidad * parseFloat(updatedRows[index].precio);
            const total = valorCantidad * parseFloat(updatedRows[index].precio) + impuestoIVADesc;
            //Actualizacion de totales
            updatedRows[index].subtotal = subtotal;
            updatedRows[index].total = total;
          } else {
            //Cuando no hay valor en updateRows
          }
        } 
    } else if (field === "cantidad" && !value) {
      updatedRows[index] = {
        ...updatedRows[index],
        cantidad: 0,
        total: 0,
      };
      
    } else if (field === "precio" && value) {
      const regex = /^\d*(\.\d*)?$/; 
      if (!regex.test(value)) {
          setShowModal(true);
          setModalMessage( `solo puede agregar numeros y para agregar decimales, agrege un punto (.)`); 
          updatedRows[index].precio = '';
          return
        }else{
          const valueParse = parseFloat(value);
          if (updatedRows) {
            const impuestoIVADesc = valueParse * parseFloat(updatedRows[index].cantidad) * (parseFloat(updatedRows[index].impuesto) / 100);
            const subtotal = valueParse * parseFloat(updatedRows[index].cantidad);
            const total = valueParse * parseFloat(updatedRows[index].cantidad) + impuestoIVADesc;
      
            //Actualizacion de totales
            updatedRows[index].precio = value;  
            updatedRows[index].subtotal = subtotal; 
            updatedRows[index].total = total; 
          }
        }
      
    } else if (field === "precio" && !value) {
      updatedRows[index] = {
        ...updatedRows[index],
        precio: 0,
        total: 0,
      };
    } else {
      updatedRows[index].total = calculateTotal(updatedRows[index]);
    }
    onRowsUpdate(updatedRows);
    setRows(updatedRows);
  };

  const calculateTotal = (row) => {
    const subtotal = row.cantidad * row.precio;
    const discountAmount = (row.descuento / 100) * subtotal;
    return subtotal - discountAmount;
  };

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        id: rows.length + 1,
        articulo: "",
        descripcion: "",
        cantidad: 0,
        precio: 0,
        subtotal: 0,
        descuento: 0,
        impuesto: 0,
        total: 0,
        residuos: [],
      },
    ]);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    onRowsUpdate(updatedRows);
    //console.log("updatedRows", updatedRows);
    setRows(updatedRows);
  };

  //Habilita el campo de precio para editarlo
  const handleDoubleClick = (index) => {
    if (editableRow !== index) {
      setEditableRow(index); // Establece la fila que será editable
      setShowModal(true);
      setModalMessage(
        `El campo de la fila ${index + 1} se habilitará para ser editado.`
      );
    } else {
      setEditableRow(null); // Desactiva la edición si vuelves a hacer doble clic
    }
  };
  // ///deshabilita el campo de precio cuando sale del foco
  // const handleBlur = () => {
  //   setIsReadOnly(true); // Deshabilitar el campo al perder el foco
  // };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalMessage("");
  };

  return (
    <div style={{ padding: "10px" }}>
      <h3 className="form-subtitle">Agregar Residuos</h3>
      <TableContainer component={Paper} style={{ overflowX: "auto", backgroundColor:"#f5f5f5" }}>
        <Table style={{ minWidth: "700px" }}>
          <TableHead className="tableHead-residuos">
            <TableRow>
              {/* Encabezados de la tabla */}
              <TableCell>Linea</TableCell>
              <TableCell>Artículo</TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell>Cantidad</TableCell>
              <TableCell>Precio</TableCell>
              <TableCell>Impuesto</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={row.id}>
                {/* Número de línea */}
                <TableCell width={"5%"}>{index + 1}</TableCell>
                <TableCell width={"15%"}>
                  {/* ARTÍCULO */}
                  <FormControl fullWidth>
                    <TextField
                      value={row.articulo}
                      onChange={(e) => handleInputChange(index, "articulo", e.target.value)}
                      placeholder="Artículo (ID)"
                      variant="outlined"
                    />
                  </FormControl>
                </TableCell>
                <TableCell width={"20%"}>
                  {/* DESCRIPCIÓN */}
                  <FormControl fullWidth>
                    <Autocomplete
                      value={{
                        res_descripcion: rows[index]?.descripcion || "",
                      }}
                      options={residuosOptions}
                      getOptionLabel={(option) => option.res_descripcion || ""}
                      onInputChange={(event, newValue) =>
                        handleInputChange(index, "descripcion", newValue)
                      }
                      onChange={(event, value) => {
                        if (value) {
                          handleInputChange(index, "descripcion", value.res_descripcion);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e) => handleInputChange(index, "descripcion", e.target.value)}
                          placeholder="Descripción"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </TableCell>
                <TableCell width={"10%"}>
                  {/* CANTIDAD */}
                  <FormControl fullWidth>
                    <TextField
                      type="text"
                      value={row.cantidad}
                      onChange={(e) => handleInputChange(index, "cantidad", e.target.value)}
                      placeholder="Cantidad"
                      variant="outlined"
                    />
                  </FormControl>
                </TableCell>
                <TableCell width={"10%"}>
                  {/* PRECIO */}
                  <FormControl fullWidth>
                    <TextField
                      title="Doble clic para editar el precio"
                      type="text"
                      value={row.precio}
                      onChange={(e) => handleInputChange(index, "precio", e.target.value)}
                      placeholder="Precio"
                      variant="outlined"
                      onDoubleClick={() => handleDoubleClick(index)} // Pasa el índice de la fila
                      InputProps={{
                        readOnly: editableRow !== index, // Solo editable si es la fila activa
                      }}
                    />
                    {showModal && (
                      <Modal
                        message={modalMessage}
                        onClose={() => setShowModal(false)}
                      />
                    )}
                  </FormControl>
                </TableCell>
                <TableCell width={"10%"}>
                  {/* IMPUESTO */}
                  <FormControl fullWidth>
                    <TextField
                      type="text"
                      value={row.impuesto || 0}
                      onChange={(e) =>
                        handleInputChange(index, "impuesto", parseFloat(e.target.value))
                      }
                      placeholder="Impuesto"
                      variant="outlined"
                      disabled={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </TableCell>
                <TableCell width={"10%"}>
                  {/* TOTAL */}
                  {row.total.toFixed(2)}
                </TableCell>
                <TableCell width={"10%"}>
                  {/* Botón Eliminar */}
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={() => handleDeleteRow(index)}
                  >
                    Eliminar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Button
          style={{ background: "#1abd9c", marginTop: "10px" }}
          onClick={handleAddRow}
          variant="contained"
        >
          Agregar Fila
        </Button>
      </TableContainer>
    </div>
  );
}

export { ResiduosTable };
