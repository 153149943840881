import axios from 'axios';
import API_URL from  './config';

export const getAlmacenes = async () => {

    try{
        const response = await axios.get(`${API_URL}/almacen/getAlmacenes`);
        return response.data

    } catch(error){
        console.error('Error obteniendo errores rtekahsdljagsd ', error );
        return [];
    }
}


export const addAlmacen = async (almacenData) => {
    try {
        const response = await axios.post(`${API_URL}/almacen/addAlmacen`, almacenData, {
            headers: {
                'Content-Type': 'application/json',
            }
    });
      return response.data;  // Devuelve los datos de respuesta de la API
    } catch (error) {
        console.error('Error al agregar el almacen', error);
        return null;
    }
}



export const updateAlmacen = async(id, almacenName) => {

    try {
        const response = await axios.put(`${API_URL}/almacen/editAlmacen/${id}`, 
            almacenName, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )

        return response.data; 
    } catch (error) {
        console.error('Error al actualizar almacen', id, error);
        return null; 
        
    }
}

export const deleteAlmacen = async (id) => {
    try {
        const response = await axios.delete(`${API_URL}/almacen/deleteAlmacen/${id}
`);
return response.data; 
    } catch (error) {
        console.error('Error al eliminar almacen',id, error); 
        return null;
    }
}