import axios from 'axios';
import API_URL from  './config';

export const getEstadoAlbaran = async () => {

    try{
        const response = await axios.get(`${API_URL}/estadoAlbaran/getEstadoAlbaran`);        
        return response.data

    } catch(error){
        console.error('Error obteniendo proveedores', error );
        return [];
    }
}

export const addEstadosAlbaran = async (estadoData) => {
    try{
        const response = await axios.post(`${API_URL}/estadoAlbaran/addEstadoAlbaran`, estadoData, {
        headers: {
            'Content-Type': 'application/json',
        } 
        });

        return response.data

    }catch(error){
        console.error('Error al agregar el estado Albarán', error);
        return null;
    }
}

export const updateEstadosAlbaran = async (id, estadoData) => {
    if (typeof estadoData !== 'object') {
        console.error('El estadoData debe ser un objeto', estadoData);
        return null;
    }

    try {
        const response = await axios.put(
            `${API_URL}/estadoAlbaran/editarEstadoAlbaran/${id}`,
            estadoData, 
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error al actualizar estado de albaran', error);
        return null; 
    }
}

export const deleteEstadoAlbaran = async(id) =>{
    try {
        const response = await axios.delete(
            `${API_URL}/estadoAlbaran/deleteEstadoAlbaran/${id}`
        );
        return response.data; 
    } catch (error) {
        console.error('Error al eliminar estadio de albaran',id); 
        return null; 
    }
}

