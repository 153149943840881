import React, { useState } from 'react';
import { Box, TextField, Button, Snackbar, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { addAlmacen, getAlmacenes } from '../service/apiAlmacenes';  // Asegúrate de que la función addAlmacen esté bien implementada

const CrearAlmacen = ({ openDialog, onClose, setSnackbarMessage, setOpenSnackbar, setAlmacenes }) => {
    const [formData, setFormData] = useState({
        alm_nombre: "",  // El nombre del almacén
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Función para manejar el envío del formulario
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validación básica
        if (formData.alm_nombre.trim() === '') {
            setSnackbarMessage('El nombre del almacén no puede estar vacío');
            setOpenSnackbar(true);
            return;
        }

        // Llamada a la API para agregar el almacén
        try {
            const response = await addAlmacen(formData);  // Asegúrate de que esta función esté bien implementada
            if (response) {
                setSnackbarMessage(`Almacén "${formData.alm_nombre}" creado con éxito`);
                setFormData({ alm_nombre: "" }); 
                const updatedAlmacenes = await getAlmacenes();  // Obtener los almacenes actualizados
                setAlmacenes(updatedAlmacenes); 
                setOpenSnackbar(true);
                onClose();  // Cerrar el modal después de la creación exitosa
            } else {
                setSnackbarMessage('Hubo un error al crear el almacén');
                setOpenSnackbar(true);
            }
        } catch (error) {
            console.error('Error al crear el almacén:', error);
            setSnackbarMessage('Hubo un error al crear el almacén');
            setOpenSnackbar(true);
        }
    };

    return (
        <Dialog open={openDialog} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Crear Almacén</DialogTitle>
            <DialogContent>
                <Box>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Nombre del Almacén"
                            variant="outlined"
                            fullWidth
                            name="alm_nombre" 
                            value={formData.alm_nombre}
                            onChange={handleChange}
                            margin="normal"
                            required
                        />

                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{ marginTop: '10px' }}
                        >
                            Crear Almacén
                        </Button>
                    </form>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CrearAlmacen;
