import React, { useState } from "react";
import { TextField, Button, Box, Typography, Grid, Container } from "@mui/material";
import { addResiduo } from "../service/apiResiduos";
import Modal from "../modal/modal";


const CrearResiduos = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [formData, setFormData] = useState({
    res_ler: "",
    res_nombre: "",
    res_cod_interno: "",
    res_descripcion: "",
    res_unidad_medida: "",
    res_iva: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
  

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await addResiduo(formData);
    if (response) {
      setShowModal(true);
      setModalMessage(`Residuo agregado con éxito`);
    } else {
      setShowModal(true);
      setModalMessage(`Error al agregar el Residuo`);
    
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalMessage("");
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ marginTop: '20px', padding: 3, backgroundColor: "#fff", borderRadius: 2, boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',}}>
        <Typography variant="h4" gutterBottom align="center">
          Crear Residuo
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Código LER"
                variant="outlined"
                name="res_ler"
                value={formData.res_ler}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nombre del Residuo"
                variant="outlined"
                name="res_nombre"
                value={formData.res_nombre}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Código Interno"
                variant="outlined"
                name="res_cod_interno"
                value={formData.res_cod_interno}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descripción"
                variant="outlined"
                name="res_descripcion"
                value={formData.res_descripcion}
                onChange={handleChange}
                required
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Unidad de Medida"
                variant="outlined"
                name="res_unidad_medida"
                value={formData.res_unidad_medida}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="IVA (%)"
                variant="outlined"
                name="res_iva"
                type="number"
                value={formData.res_iva}
                onChange={handleChange}
                required
                inputProps={{ min: 0 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" fullWidth type="submit">
                Crear Residuo
              </Button>
              {showModal && (
                      <Modal
                        message={modalMessage}
                        onClose={() => setShowModal(false)}
                      />
                    )}
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default CrearResiduos;
