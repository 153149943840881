import React, { useState, useEffect } from "react";
import "./styles/form.css";
import { addSeriesConsecutivo } from "../service/apiSeries";
import { ProveedoresSelect } from "../components/proveedorSelect";
import { EstadosSelect } from "../components/estadosSelect";
import { SeriesSelect } from "../components/seriesSelect";
import { FormControl, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ResiduosTable } from "../components/residuosTable";
import { AlmacenesSelect } from "../components/almacenesSelect";
import PdfGenerator from "../components/pdfGenerator";
import Modal from "../modal/modal";
import { Button, Select, MenuItem, InputLabel, Box, Typography, Grid} from "@mui/material";
import { format } from "date-fns";
import { addAlbaran, addLinea } from "../service/apiAlbaran";


function CrearAlbaran() {
    
    const [selectedOption, setSelectedOption] = useState("");
    const [inputNumero, setInputNumero] = useState("");
    const [currentDate, setCurrentDate] = useState(new Date());
    const [suRef, setSuRef] = useState("");
    const [estadoAlbaran, setEstadoAlbaran] = useState("");
    const [almacen, setAlmacen] = useState("");
    const [albaranEntrada, setAlbaranEntrada] = useState("");
    const [codCliente, setCodCliente] = useState("");
    const [almacenId, setAlmacenId] = useState(null);
    const [proveedor, setProveedor] = useState("");
    const [selectedSerie, setSelectedSerie] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    const [showModal, setShowModal] = useState(false); 
    const [modalMessage, setModalMessage] = useState("");
    const [message, setMessage] = useState("");
    const [consecutivo, setConsecutivo] = useState(null);
    const [rows, setRows] = useState([]);
    const [pdfData, setPdfData] = useState(null);
    const [metodoPago, setMetodoPago] = useState('');
    const [nifCliente, setNifCliente] = useState('');
    const [numTarjeta, setNumTarjeta] = useState('');
    const [numCuenta, setNumCuenta] = useState('');
    const [matricula, setMatricula] = useState(''); 
    const [habilitarMatricula, setHabilitarMatricula] = useState(false); 
    const [pesoCamionEntrada, setpesoCamionEntrada] = useState(0);
    const [pesoCamionSalida, setpesoCamionSalida] = useState(0);
    const [pesoFinal, setpesoFinal] = useState(0);


    const metodosPago = [{
        id:1,
        name: "Efectivo"
    }, {
        id:2,
        name: "Transferencia Bancaria"
    }, {
        id:3,
        name: "Ecopay Card"
    }]
    const handleChange = (event) => {
        //console.log(event);
        setMetodoPago(event.target.value);
    };
    // Mostrar notificación (no relacionado con la fecha, pero sirve para mostrar elmodal)
    const handleShowNotification = () => {
        setMessage("¡Esto es una notificación!");
        setShowModal(true);
    };
    // Esta función se dispara con el doble clic en el campo de fecha
    const handleDateDoubleClick = () => {
        setIsDisabled(false);
        setShowModal(true);
        setModalMessage("¡Este campo se habilitará. para ser editado!");
    }
    const handleCloseModal = () => {
        setShowModal(false);
        setModalMessage("");
    };
    const handleDateChange = (newValue) => {
        setCurrentDate(newValue);
        setIsDisabled(true);
    };
    const handleChangeMatricula = (event) => {
        if (habilitarMatricula) {
            setMatricula(event.target.value);
        }
    };
    const handleDoubleClick = () => {
        setHabilitarMatricula(true); // Habilita la edición
        setShowModal(true); // Muestra el modal
        setModalMessage("¡Este campo se habilitará para ser editado!");
    };
    const handleAlmacenChange = (id, name) => {
        setAlmacenId({ id: id, nombre: name });
    };
    const handleEstadoChange = (newState) => {
        setSelectedState(newState);
    };
    const handleSelectedSerieChange = (newSerie) => {
        setSelectedSerie(newSerie);
    };
    const handleSelectedProveedor = (newProveedor) => {
        setProveedor(newProveedor);
    };
    const handleRowsUpdate = (updatedRows) => {
        setRows(updatedRows);
        //console.log("Updated rows in parent:", updatedRows);
    };
    const handleChangePesoEntrada = (value) => {
        setpesoCamionEntrada(value);
    }
    const handleChangePesoSalida = (value) => {
        setpesoCamionSalida(value);
    }
    //Esta funcion se usa para crear el consecutivo de los albaranes por empresa y por particulares. 
    const handlePruebaConsecutivo = async() =>{

        if(!selectedSerie){
            alert("Debe Seleccionar la serie del Albarán")
        }else{   
            const result = await addSeriesConsecutivo(selectedSerie, consecutivo);
            if(result.includes("Consecutivo agregado con exito con id:")){
                setConsecutivo(consecutivo + 1);
            }
        }
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };
    const handleClick = async (e) => {
        e.preventDefault();

        
        
        if(!selectedSerie){
            setShowModal(true);
            setModalMessage("Debe seleccionar una Serie Albarán");
            return;
        }else if(!selectedState){
            setShowModal(true);
            setModalMessage("Debe seleccionar un Estado de Albarán");
            return
        }else if(!metodoPago){
            setShowModal(true);
            setModalMessage("Debe seleccionar un Método de pago");
        }else if(!proveedor){
            setShowModal(true);
            setModalMessage("Debe seleccionar un Proveedor");
            return
        }else if(!codCliente){
            setShowModal(true);
            setModalMessage("Debe diligenciar el campo Código Cliente ");
            return
        }else if(!almacenId){
            setShowModal(true);
            setModalMessage("Debe seleccionar un Almacen");
            return
        }else if(rows.length == 0){
            setShowModal(true);
            setModalMessage("Debe diligenciar los Residuos para crear el Albarán");
            return
        }
        // console.log("¡Botón presionado!");
        // console.log("Serie Albaran: ", selectedSerie + " - " + consecutivo);
        // console.log("suRef:", suRef);
        // console.log("Estados de Albarán:", selectedState);
        // console.log("Fecha:", format(currentDate, "dd-MM-yyyy"));
        // console.log("Proveedor: ", proveedor);
        // console.log("Codigo cliente: ", codCliente);
        // console.log("Almacen: ", almacenId.nombre);
        // console.log("Albarán de entrada: ", albaranEntrada);
        // console.log("Tabla de Albarán: ", rows );
        // console.log('impuestos', allImpuestos)
        
        const allImpuestos = rows.map(row => row.impuesto);
        
        // Validar cantidades
        const areQuantitiesValid = rows.every((row) => {
            return typeof row.cantidad === "number" || row.cantidad > 0;
        });

        if (!areQuantitiesValid) {
            alert("Por favor, verifica que todas las filas tengan cantidades válidas antes de proceder.");
            return; // Detener el flujo si hay cantidades inválidas
        }


        const totalSum = rows.reduce((accumulator, item) => accumulator + item.total, 0);
        //console.log("Suma total:", totalSum);
        handlePruebaConsecutivo()
        setPdfData({
            selectedSerie,
            consecutivo,
            suRef,
            selectedState,
            currentDate,
            proveedor,
            codCliente,
            almacenId,
            albaranEntrada,
            metodoPago,
            nifCliente,
            numTarjeta,
            numCuenta,
            matricula,
            rows,
            allImpuestos,
            totalSum,
            pesoCamionEntrada,
            pesoCamionSalida,
            pesoFinal,

        });

        try {
            // Crear el albarán
            const albaranData = {
                alb_fecha_creacion: formatDate(currentDate),
                alb_proveedor_id: codCliente,
                alb_almacen_id: almacenId.id,
                alb_estado_id: estadoAlbaran,
                alb_su_ref: suRef
            };

            const  albaranId  = await addAlbaran(albaranData);
            console.log('albaranId',albaranId);

            //Crear las líneas de albarán
            for (const row of rows) {
            const lineaData = {
                lin_alb_id: albaranId,
                lin_res_id: row.articulo,
                cantXKilo: parseFloat(row.cantidad),
                precioXalmacen: parseFloat(row.precio),
                descuento: 0,
                subtotal: parseFloat(row.subtotal),
                tipo_iva: parseFloat(row.impuesto),
                pesoInicial: 0,
                peso_sumado:  0,
                peso_final: 0,
            };
    
            await addLinea(lineaData);
            }
    
            console.log("Albarán y líneas creados con éxito.");
            setRows([]); // Limpia los datos después de enviar
        } catch (error) {
            console.log("Hubo un error al procesar el albarán o las líneas.");
            console.error(error);
        }

    
        window.location.reload()
        //Reasignar valores de los campos una vez, se cree el albaran. 
        // setSelectedSerie('');
        // setSuRef('');
        // setSelectedState('');
        // setProveedor('');
        // setCodCliente('');
        // setMatricula('');
        // setAlmacenId('');
        // setAlbaranEntrada('');
        // setMetodoPago('');
        // setRows('');
        

    };
    useEffect(() => {
        const today = new Date();
        setCurrentDate(today);
        if (pesoCamionEntrada && pesoCamionSalida) {
            const final = parseFloat(pesoCamionEntrada) - parseFloat(pesoCamionSalida);
            setpesoFinal(final || 0); // Asegura que no sea NaN
        }
    }, [pesoCamionEntrada, pesoCamionSalida])
    const handleConsecutivoChange = (nuevoConsecutivo) => {
        setConsecutivo(nuevoConsecutivo + 1 || 0);
    };
    const handleChangeIdCliente = (valorIdCliente) => {
        if(valorIdCliente){
            setCodCliente(valorIdCliente)
        }else{
            setCodCliente('');
        }
    }
    const handleNifCliente = (valorNifCliente) =>{
        if(valorNifCliente){
            setNifCliente(valorNifCliente)
        }else {
            setNifCliente('')
        }
    }
    const handleNumTarjeta = (valorNumTarjeta) =>{
        if(valorNumTarjeta){
            setNumTarjeta(valorNumTarjeta)
        }else {
            setNumTarjeta('')
        }
    }
    const handleNumCuenta = (valorNumCuenta) =>{
        if(valorNumCuenta){
            setNumCuenta(valorNumCuenta);
        }else {
            setNumCuenta('');
        }
    }
    const handleMatricula = (valorMatricula) => {
        if(valorMatricula){
            setMatricula(valorMatricula);
        }else {
            setMatricula('');
        }
    }
    const handleCodEstadoAlbaran = (codEstado) => {
        if(codEstado){
            setEstadoAlbaran(codEstado)
        }else {
            setEstadoAlbaran('')
        }
    } 

    return (
    <>

        <h2 className="form-title">Ingreso de Albaranes</h2>
        <form className="form-container">
            <div className="form-grid">
                <div>
                <SeriesSelect
                    onSerieChange={handleSelectedSerieChange}
                    ValorConsecutivo={handleConsecutivoChange}
                />
                </div>
                <div>
                    <TextField
                        label="Consecutivo"
                        variant="outlined"
                        value={consecutivo || 0}
                        fullWidth
                        InputLabelProps={{
                        shrink: true, // Forza el label a mantenerse arriba incluso si está vacío
                    }}
                    />
                </div>
                <div>
                    <TextField
                        label="Su Ref"
                        variant="outlined"
                        fullWidth
                        value={suRef || ''}
                        onChange={(e) => setSuRef(e.target.value)}
                    />
                </div>

                <div>
                    <EstadosSelect
                        onEstadoChange={handleEstadoChange}
                        codCliente = {handleCodEstadoAlbaran}
                    />
                </div>

                <div style={{ width: '100%', height: '100%', cursor: "pointer"}} 
                    title="Doble clic para editar la fecha" >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div 
                        style={{
                                width: '100%', 
                                height: '100%', 
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'center',
                            }}
                        onDoubleClick={handleDateDoubleClick}>
                            <DatePicker
                                fullWidth
                                label="Fecha"
                                value={currentDate}
                                onChange={handleDateChange}
                                readOnly={isDisabled}
                                renderInput={(params) => (
                                <TextField fullWidth {...params}  />
                            )}
                            /> 
                        </div>
                    </LocalizationProvider>

                    {/* Mostrar el modal si showModal es true */}
                    {showModal && (
                    <Modal message={modalMessage} onClose={handleCloseModal} />
                    )}
                </div>

                <div> {//Proveedor 
                    }
                    <ProveedoresSelect 
                        onProveedorChange={handleSelectedProveedor}
                        valorIdCliente = {handleChangeIdCliente}
                        nifCliente = {handleNifCliente}
                        numTarjeta={handleNumTarjeta}
                        numCuenta = {handleNumCuenta}
                        matricula={handleMatricula}
                    />
                </div>

                <div> 
                    <TextField //Codigo Cliente
                        style={{ width: '48%', margin: "auto 2% auto 0"}}    
                        label="Código Cliente"
                        variant="outlined"
                        fullWidth
                        value={codCliente || ''}
                        readOnly={true}
                    />
                    <TextField // Matrícula
                        style={{ width: "48%", margin: "auto 0 auto 2%" }}
                        label="Matrícula"
                        variant="outlined"
                        fullWidth
                        readOnly={!habilitarMatricula} 
                        onChange={handleChangeMatricula}
                        onDoubleClick={handleDoubleClick} 
                        value={matricula || ''}
                    />
                </div>
                <div>
                    <AlmacenesSelect 
                        onAlmacenChange={handleAlmacenChange} 
                    />
                </div>

                <div>
                    <TextField
                        label="Albarán de entrada"
                        variant="outlined"
                        value={albaranEntrada}
                        fullWidth
                        onChange={(e) => setAlbaranEntrada(e.target.value)}
                        className="form-control"
                    />
                </div>
                <div> 
                    <FormControl fullWidth>
                        <InputLabel id="metodo-label">Método de pago 
                        </InputLabel>
                        <Select
                                labelId = "metodo-label"
                                value={metodoPago}
                                label = "Método de Pago"
                                onChange={handleChange}
                            >
                            {metodosPago.map((metodo) => (
                                <MenuItem key={metodo.id} value={metodo.name}>
                                    {metodo.name}
                                </MenuItem>
                            ))}

                        </Select>
                    </FormControl>
                </div>
            </div>
        </form>

        <div className="table-container">
            <ResiduosTable 
                almacenId={almacenId}
                rowsP={rows} 
                onRowsUpdate={handleRowsUpdate} 
            />
        </div>
        <Box
            sx={{
                maxWidth: '80%',
                margin: '20px auto',
                padding: '20px',
                borderRadius: '10px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#f5f5f5',
                display: 'flex',
                flexDirection: 'column',  
            }}
        >
            
            <h3 style={{ textAlign: "left" }} className="form-subtitle">Peso de Carga</h3>
            <Grid container spacing={3} justifyContent="center" alignItems="center">
                <Grid item xs={4}>
                    <TextField
                        label="Peso Camion Entrada"
                        type="number"
                        value={pesoCamionEntrada}
                        onChange={(e) => 
                            handleChangePesoEntrada(e.target.value)
                        }
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        size="small" // Ajusta el tamaño de los inputs
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Peso Camion Salida"
                        type="number"
                        value={pesoCamionSalida}
                        onChange={(e) => 
                            handleChangePesoSalida(e.target.value)
                        }
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        size="small"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Peso Final"
                        type="number"
                        value={pesoFinal}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        size="small"
                    />
                </Grid>
            </Grid>
        </Box>


        <div style={{ margin: '40px auto', textAlign: 'right', width: '80%' }}>
            <Button style={{background:'#1abd9c', margin: '10px auto'}} variant="contained" color="primary" onClick={handleClick}>
                Crear Albarán
            </Button>
            {showModal && (
                <Modal message={modalMessage} onClose={handleCloseModal} />
            )}
            {pdfData && <PdfGenerator data={pdfData} />}
            
        </div>

        {/* { <div style={{ margin: '10px' }}>
            <Button variant="contained" color="primary" onClick={handlePruebaConsecutivo}>Prueba consecutivo</Button>
        </div> } */}
    </>
    );
}

export { CrearAlbaran };
