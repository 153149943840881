import React, {useState, useEffect, useDebugValue} from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper, Snackbar, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { getResiduos, updateResiduos } from '../service/apiResiduos';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';



const UpdateResiduos = () => {

    const [residuos, setResiduos] = useState([]);
    const [residuoEliminar, setResiduoEliminar] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false); 
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [openDialog, setOpenDialog] = useState(false); 
    const [selectResiduo, setSelectedResiduo] = useState({
        res_id: 0,
        res_ler: "",
        res_nombre: "",
        res_cod_interno: "",
        res_descripcion: "",
        res_unidad_medida: "",
        res_iva: 0
    });  
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false); 


    useEffect(() => {
        const fetchResiduos = async () => {
            try {
                const response = await getResiduos();
                setResiduos(response);

            } catch (error) {
                console.error("Error al obtener los residuos", error); 
            }
        }
        fetchResiduos();

    }, [])

    const handleEdit = (residuo) =>{
        setSelectedResiduo(residuo);
        setOpenDialog(true); 
    }

    const handleDelete = (residuo) => {
        setResiduoEliminar(residuo);
        setOpenConfirmDialog(true);
    }

    // const confirmDelete = async() =>{
    //     try {
    //         const response = '' //await deleteResiduos(residuoEliminar.res_id)
    //         if(response){
    //             setSnackbarMessage('Residuo eliminado correctamente');
    //             setShowSnackbar(true);
    //             setOpenConfirmDialog(false);

    //             const UpdateResiduos = await getResiduos();
    //             setResiduos(UpdateResiduos);
    //         }
    //     } catch (error) {
    //         console.error('Error al eliminar Residuo:', error);
    //         setSnackbarMessage('Error al eliminar el residuo');
    //         setShowSnackbar(true);
    //         setOpenConfirmDialog(false);
    //     }
    // }

    // const cancelDelete = () =>{
    //     setOpenConfirmDialog(false); 
    // }

    const handleInputChange = (e)=>{
        const { name, value } = e.target;
        setSelectedResiduo(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }

    const handleSubmitEdit = async()=>{

        try {
            const dataToSend = {
                res_ler: selectResiduo.res_ler,
                res_nombre: selectResiduo.res_nombre,
                res_cod_interno: selectResiduo.res_cod_interno,
                res_descripcion: selectResiduo.res_descripcion,
                res_unidad_medida: selectResiduo.res_unidad_medida,
                res_iva: selectResiduo.res_iva
            }

            const response = await updateResiduos(selectResiduo.res_id, dataToSend);
            if (response) {
                setSnackbarMessage('Estado actualizado correctamente');
                setShowSnackbar(true);
                setOpenDialog(false);
                // Volver a cargar los estados
                const updateResiduos = await getResiduos();
                setResiduos(updateResiduos);
            }
        } catch (error) {
            console.error('Error al actualizar residuo:', error);
            setSnackbarMessage('Error al actualizar el residuo');
            setShowSnackbar(true);
        }
    } 


    return(
        <>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <TableContainer 
                    component={Paper} 
                    sx={{ 
                    maxWidth: '90%', 
                    width: '100%', 
                    boxShadow: 3,
                    maxHeight: '700px',  
                    overflowY: 'auto' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Nombre</strong></TableCell>
                            <TableCell><strong>Código Ler</strong></TableCell>
                            <TableCell><strong>Código Interno</strong></TableCell>
                            <TableCell><strong>Descripción</strong></TableCell>
                            <TableCell><strong>Unidad de Medida</strong></TableCell>
                            <TableCell><strong>IVA</strong></TableCell>

                            <TableCell align="center"><strong>Acciones</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {residuos.map((residuo) => (
                        <TableRow 
                            key={residuo.res_id}>
                            <TableCell>{residuo.res_nombre}</TableCell>
                            <TableCell>{residuo.res_ler}</TableCell>
                            <TableCell>{residuo.res_cod_interno}</TableCell>
                            <TableCell>{residuo.res_descripcion}</TableCell>
                            <TableCell>{residuo.res_unidad_medida}</TableCell>
                            <TableCell>{residuo.res_iva}</TableCell>
                            <TableCell align="center">
                                {/* Botón de Editar */}
                                <Button
                                    onClick={() => handleEdit(residuo)}
                                    variant="outlined"
                                    color="primary"
                                    sx={{ marginRight: 1, padding: '6px' }}
                                >
                                    <FaEdit />
                                </Button>
                                {/* Botón de Eliminar */}
                                {/* <Button
                                    onClick={() => handleDelete(residuo)} 
                                    variant="outlined"
                                    color="error"
                                    sx={{ padding: '6px' }}
                                >
                                    <FaTrashAlt />
                                </Button> */}
                            </TableCell>
                        </TableRow>

                        ))}
                    </TableBody>

                </Table>
                </TableContainer>
            {/* Snackbar para mensajes de éxito/error */}
            <Snackbar
                open={showSnackbar}
                autoHideDuration={6000}
                onClose={() => setShowSnackbar(false)}
                message={snackbarMessage}
            />

            {/* Diálogo para editar residuo */}
            <Dialog 
                open={openDialog} 
                onClose={() => setOpenDialog(false)} 
                maxWidth="md"  
                fullWidth  
            >
                <DialogTitle sx={{ marginBottom: '20px' }}>
                    Editar Residuo
                </DialogTitle>
                <DialogContent style={{ paddingTop: '10px' }}>
                    <TextField
                        label="Nombre"
                        style={{ marginBottom: '10px', paddingTop: '0' }} 
                        variant="outlined"
                        fullWidth  
                        name="res_nombre"
                        value={selectResiduo.res_nombre}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="Código Ler"
                        style={{ marginBottom: '10px', paddingTop: '0' }} 
                        variant="outlined"
                        fullWidth  
                        name="res_ler"
                        value={selectResiduo.res_ler}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="Código interno"
                        style={{ marginBottom: '10px', paddingTop: '0' }} 
                        variant="outlined"
                        fullWidth  
                        name="res_cod_interno"
                        value={selectResiduo.res_cod_interno}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="Descripción"
                        style={{ marginBottom: '10px', paddingTop: '0' }} 
                        variant="outlined"
                        fullWidth  
                        name="res_descripcion"
                        value={selectResiduo.res_descripcion}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="Unidad de Medida"
                        style={{ marginBottom: '10px', paddingTop: '0' }} 
                        variant="outlined"
                        fullWidth  
                        name="res_unidad_medida"
                        value={selectResiduo.res_unidad_medida}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="IVA"
                        style={{ marginBottom: '10px', paddingTop: '0' }} 
                        variant="outlined"
                        fullWidth  
                        name="res_iva"
                        value={selectResiduo.res_iva}
                        onChange={handleInputChange}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setOpenDialog(false)} color="error">Cancelar </Button>
                    <Button onClick={handleSubmitEdit} color="primary">Guardar Cambios </Button>
                </DialogActions>
            </Dialog>
            {/* Diálogo de confirmación de eliminación */}

            {/* <Dialog 
                open={openConfirmDialog} 
                onClose={cancelDelete} 
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>¿Estás seguro de eliminar el residuo: <strong>{residuoEliminar ? residuoEliminar.res_nombre: '' }</strong> ?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={cancelDelete} color="secondary">
                        Cancelar
                    </Button>
                    <Button onClick={confirmDelete} color="error">
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog> */}

            </div>
        </>
    )
}

export default UpdateResiduos;