import React, { useState } from 'react';

import {ResiduosList} from './components/residuosList';
import {ProveedoresSelect} from  './components/proveedorSelect';
import { Button, Select, MenuItem, InputLabel, Box, Typography, Grid} from "@mui/material";
import Navbar from './navBar/navBar';
import CrearAlmacen from './crudComponents/createAlmacen'; 
import  CrearResiduo  from './crudComponents/createResiduos'; 
import { CrearAlbaran } from './form/crearAlbaran'; 
import CrearProveedor from './crudComponents/createProveedor'; 
import CrearEstados from './crudComponents/createEstados';
import CrearPrecioResiduo from './crudComponents/createPrecioResiduo';
import UpdateEstados from './crudComponents/updateEstados';
import UpdateAlmacenes from './crudComponents/updateAlmacenes';
import UpdateResiduos from './crudComponents/updateResiduos';
import UpdateProveedores from './crudComponents/updateProveedores';

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';


//import logo from '../public/logo192.png'; // Asegúrate de tener el logo en la carpeta adecuada
import './App.css';

function App() {

    return(
        <>
            <Router>
                <Navbar />
                <Routes>
                    <Route path="/" element={<Navigate to="/crear-albaran" />} />
                    {/* Crear  */}
                    <Route path="/crear-albaran" element={<CrearAlbaran />} />
                    <Route path="/crear-almacen" element={<CrearAlmacen />} />
                    <Route path="/crear-residuo" element={<CrearResiduo />} />
                    <Route path="/crear-proveedor" element={<CrearProveedor />} />
                    <Route path="/crear-estados" element={<CrearEstados />} />
                    <Route path="/crear-precio" element={<CrearPrecioResiduo />} />

                    {/* Actualizar */}
                    <Route path="/actualizar-estado" element={<UpdateEstados />}/>
                    <Route path="/almacenes" element={<UpdateAlmacenes />}/>
                    <Route path="/actualizar-residuo" element={<UpdateResiduos />}/>
                    <Route path="/actualizar-proveedor" element={<UpdateProveedores/>}/>
                </Routes>
            </Router>
        </>

    )}

export default App;
