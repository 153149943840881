import React from 'react';
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate

export default function Navbar() {
    const [anchorElAlm, setAnchorElAlm] = React.useState(null);
    const [anchorElEst, setAnchorElEst] = React.useState(null);
    const [anchorElPro, setAnchorElPro] = React.useState(null);
    const [anchorElAlb, setAnchorElAlb] = React.useState(null);
    const [anchorRes, setAnchorRes] = React.useState(null);
    const navigate = useNavigate(); 


    // Manejo de apertura y cierre de los menús
    const handleMenuOpen = (setter) => (event) => {
        setter(event.currentTarget);
    };

    const handleMenuClose = (setter) => () => {
        setter(null);
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: '#1abd9c' }}>
            <Toolbar sx={{ justifyContent: 'left' }} style={{width :'80%', margin:'auto'}} > {/* Centrar contenido en el Toolbar */}
                <Box display="flex" gap={4} alignItems="center"> {/* Espaciado entre botones */}

                    {/* Menú Albaranes */}
                    <Button
                        color="inherit"
                        onClick={handleMenuOpen(setAnchorElAlb)}
                        sx={{ fontSize: '1rem' }} // Ajuste de tamaño del texto
                    >
                        Albaranes 
                    </Button>
                    <Menu
                        anchorEl={anchorElAlb}
                        open={Boolean(anchorElAlb)}
                        onClose={handleMenuClose(setAnchorElAlb)}
                    >
                        <MenuItem 
                            onClick={() => {
                                handleMenuClose(setAnchorElAlb)();
                                navigate('/crear-albaran'); 
                            }}
                            >Crear Albaran</MenuItem>
                        <MenuItem onClick={handleMenuClose(setAnchorElAlb)}>Editar Albaran</MenuItem>
                        <MenuItem onClick={handleMenuClose(setAnchorElAlb)}>Eliminar Albaran</MenuItem>
                    </Menu>

                    {/* Menú Almacenes */}
                    <Button
                        color="inherit"
                        sx={{ fontSize: '1rem' }} 
                        onClick= {() => {
                            handleMenuClose(setAnchorElAlm)();
                            navigate('/almacenes'); 
                        }}
                    >
                        Almacenes
                    </Button>

                    {/* Menú Estados */}
                    <Button
                        color="inherit"
                        onClick={handleMenuOpen(setAnchorElEst)}
                        sx={{ fontSize: '1rem' }}
                    >Estados</Button>
                    <Menu
                        anchorEl={anchorElEst}
                        open={Boolean(anchorElEst)}
                        onClose={handleMenuClose(setAnchorElEst)}
                    >
                        <MenuItem 
                            onClick={() => {
                                handleMenuClose(setAnchorElEst)();
                                navigate('/crear-estados'); 
                            }}
                        >Crear Estado</MenuItem>
                        <MenuItem 
                        onClick={() => {
                            handleMenuClose(setAnchorElEst)();
                            navigate('/actualizar-estado'); 
                        }} 
                        >Editar Estado</MenuItem>
                    </Menu>

                    {/* Menú Proveedores */}
                    <Button
                        color="inherit"
                        onClick={handleMenuOpen(setAnchorElPro)}
                        sx={{ fontSize: '1rem' }}
                    >Proveedores</Button>

                    <Menu
                        anchorEl={anchorElPro}
                        open={Boolean(anchorElPro)}
                        onClose={handleMenuClose(setAnchorElPro)}
                    >
                        <MenuItem  onClick={() => {
                            handleMenuClose(setAnchorElPro)();
                            navigate('/crear-proveedor'); 
                            }}
                        >Crear Proveedor</MenuItem>

                        <MenuItem onClick={() => {
                            handleMenuClose(setAnchorElPro)();
                            navigate('/actualizar-proveedor'); 
                            }}
                        >Editar Proveedor</MenuItem>
                    </Menu>

                    {/* Menú Residuos */}
                    <Button
                        color="inherit"
                        onClick={handleMenuOpen(setAnchorRes)}
                        sx={{ fontSize: '1rem' }}
                    >Residuos</Button>
                    <Menu
                        anchorEl={anchorRes}
                        open={Boolean(anchorRes)}
                        onClose={handleMenuClose(setAnchorRes)}
                    >
                        <MenuItem
                            onClick={() => {
                                handleMenuClose(setAnchorRes)();
                                navigate('/crear-residuo');
                            }}
                        >Crear Residuo</MenuItem>

                        <MenuItem  onClick={() => {
                                handleMenuClose(setAnchorRes)();
                                navigate('/crear-precio');
                            }}
                        >Agregar Precio Residuo</MenuItem>

                        <MenuItem onClick={() => {
                                handleMenuClose(setAnchorRes)();
                                navigate('/actualizar-residuo'); 
                            }}
                        >Editar Residuo</MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    );
}
