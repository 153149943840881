import React, {useState, useEffect, useDebugValue} from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper, Snackbar, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { getProveedores, updateProveedores } from '../service/apiProveedores';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';



const UpdateProveedores = () =>{
    const [proveedores, setProveedores] = useState([]);
    const [proveedorEliminar, setProveedorEliminar] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false); 
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [openDialog, setOpenDialog] = useState(false); 
    const [selectProveedor, setSelectedProveedor] = useState({
        prov_id: 0,
        prov_nombre: "",
        prov_direccion: "",
        prov_codigo_postal: "",
        prov_poblacion: "",
        prov_provincia: "",
        prov_nif: "",
        prov_telefono: 0,
        prov_email: "",
        prov_cta_bancaria: "",
        prov_num_tarjeta_ecopay: 0,
        prov_total_op_anual: 0,
        prov_matricula: "",
    });  
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false); 

    useEffect(() => {
        const fetchPRoveedores = async () => {
            try {
                const response = await getProveedores();
                setProveedores(response);

            } catch (error) {
                console.error("Error al obtener los proveedores", error); 
            }
        }
        fetchPRoveedores();

    }, [])

    const handleSubmitEdit = async() =>{
        try {
            const dataToSend = {
                prov_nombre: selectProveedor.prov_nombre,
                prov_direccion: selectProveedor.prov_direccion,
                prov_codigo_postal: selectProveedor.prov_codigo_postal,
                prov_poblacion: selectProveedor.prov_poblacion,
                prov_provincia: selectProveedor.prov_provincia,
                prov_nif: selectProveedor.prov_nif,
                prov_telefono:selectProveedor.prov_telefono,
                prov_email: selectProveedor.prov_email,
                prov_cta_bancaria: selectProveedor.prov_cta_bancaria,
                prov_num_tarjeta_ecopay:selectProveedor.prov_num_tarjeta_ecopay,
                prov_total_op_anual:selectProveedor.prov_total_op_anual,
                prov_matricula: selectProveedor.prov_matricula,
            }

            const response = await updateProveedores(selectProveedor.prov_id, dataToSend);

            if(response){
                setSnackbarMessage('Proveedor actualizado correctamente');
                setShowSnackbar(true);
                setOpenDialog(false);

                const updateProveedores = await getProveedores();
                setProveedores(updateProveedores);
            }

        } catch (error) {
            console.error('Error al actualizar proveedor:', error);
            setSnackbarMessage('Error al actualizar el residuo');
            setShowSnackbar(true);
        }
    }
    const handleEdit= (proveedor) =>{
        setSelectedProveedor(proveedor);
        setOpenDialog(true);
    }

    const handleInputChange = (e)=>{
        const {name, value} = e.target;
        setSelectedProveedor(prevState => ({
            ...prevState, 
            [name]: value,
        }))
    }

    return(
        <>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <TableContainer 
                    component={Paper} 
                    sx={{ 
                    maxWidth: '90%', 
                    width: '100%', 
                    boxShadow: 3,
                    maxHeight: '658u0px',  
                    overflowY: 'auto',
                    overflowX: 'auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>Nombre</strong></TableCell>
                                <TableCell><strong>Dirección</strong></TableCell>
                                <TableCell><strong>Código Postal</strong></TableCell>
                                <TableCell><strong>Población</strong></TableCell>
                                <TableCell><strong>Provincia</strong></TableCell>
                                <TableCell><strong>NIF</strong></TableCell>
                                <TableCell><strong>Teléfono</strong></TableCell>
                                <TableCell><strong>Email</strong></TableCell>
                                <TableCell><strong>Cta Bancaria</strong></TableCell>
                                <TableCell><strong>Tarjeta Ecopay</strong></TableCell>
                                <TableCell><strong>Total Op Anual</strong></TableCell>
                                <TableCell><strong>Matricula</strong></TableCell>
                                <TableCell align="center"><strong>Acciones</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {proveedores.map((proveedor)=> (
                                <TableRow Key={proveedor.prov_id}>
                                    <TableCell>{proveedor.prov_nombre}</TableCell>
                                    <TableCell>{proveedor.prov_direccion}</TableCell>
                                    <TableCell>{proveedor.prov_codigo_postal}</TableCell>
                                    <TableCell>{proveedor.prov_poblacion}</TableCell>
                                    <TableCell>{proveedor.prov_provincia}</TableCell>
                                    <TableCell>{proveedor.prov_nif}</TableCell>
                                    <TableCell>{proveedor.prov_telefono}</TableCell>
                                    <TableCell>{proveedor.prov_email}</TableCell>
                                    <TableCell>{proveedor.prov_cta_bancaria}</TableCell>
                                    <TableCell>{proveedor.prov_num_tarjeta_ecopay}</TableCell>
                                    <TableCell>{proveedor.prov_total_op_anual}</TableCell>
                                    <TableCell>{proveedor.prov_matricula}</TableCell>
                                    <TableCell align="center">
                                        {/* Botón de Editar */}
                                        <Button
                                            onClick={() => handleEdit(proveedor)}
                                            variant="outlined"
                                            color="primary"
                                            sx={{ marginRight: 1, padding: '6px' }}
                                        >
                                            <FaEdit />
                                        </Button>
                                        {/* Botón de Eliminar */}
                                        {/* <Button
                                            onClick={() => handleDelete(residuo)} 
                                            variant="outlined"
                                            color="error"
                                            sx={{ padding: '6px' }}
                                        >
                                            <FaTrashAlt />
                                        </Button> */}
                                    </TableCell>
                                </TableRow>
                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>
                <Snackbar
                    open={showSnackbar}
                    autoHideDuration={6000}
                    onClose={() => setShowSnackbar(false)}
                    message={snackbarMessage}
                />

                {/* Diálogo para editar proveedor */}
                <Dialog 
                    open={openDialog} 
                    onClose={() => setOpenDialog(false)} 
                    maxWidth="md"  
                    fullWidth  
                >
                    <DialogTitle sx={{ marginBottom: '20px' }}>
                        Editar Proveedor
                    </DialogTitle>
                    <DialogContent style={{ paddingTop: '10px' }}>
                        <TextField
                            label="Nombre"
                            style={{ marginBottom: '10px', paddingTop: '0' }} 
                            variant="outlined"
                            fullWidth  
                            name="prov_nombre"
                            value={selectProveedor.prov_nombre}
                            onChange={handleInputChange}
                        />
                        <TextField
                            label="Dirección"
                            style={{ marginBottom: '10px', paddingTop: '0' }} 
                            variant="outlined"
                            fullWidth  
                            name="prov_direccion"
                            value={selectProveedor.prov_direccion}
                            onChange={handleInputChange}
                        />
                        <TextField
                            label="Código Postal"
                            style={{ marginBottom: '10px', paddingTop: '0' }} 
                            variant="outlined"
                            fullWidth  
                            name="prov_codigo_postal"
                            value={selectProveedor.prov_codigo_postal}
                            onChange={handleInputChange}
                        />
                        <TextField
                            label="Población"
                            style={{ marginBottom: '10px', paddingTop: '0' }} 
                            variant="outlined"
                            fullWidth  
                            name="prov_poblacion"
                            value={selectProveedor.prov_poblacion}
                            onChange={handleInputChange}
                        />
                        <TextField
                            label="Provincia"
                            style={{ marginBottom: '10px', paddingTop: '0' }} 
                            variant="outlined"
                            fullWidth  
                            name="prov_provincia"
                            value={selectProveedor.prov_provincia}
                            onChange={handleInputChange}
                        />
                        <TextField
                            label="Nif"
                            style={{ marginBottom: '10px', paddingTop: '0' }} 
                            variant="outlined"
                            fullWidth  
                            name="prov_nif"
                            value={selectProveedor.prov_nif}
                            onChange={handleInputChange}
                        />
                        <TextField
                            label="Teléfono"
                            style={{ marginBottom: '10px', paddingTop: '0' }} 
                            variant="outlined"
                            fullWidth  
                            name="prov_telefono"
                            value={selectProveedor.prov_telefono}
                            onChange={handleInputChange}
                        />
                        <TextField
                            label="Email"
                            style={{ marginBottom: '10px', paddingTop: '0' }} 
                            variant="outlined"
                            fullWidth  
                            name="prov_email"
                            value={selectProveedor.prov_email}
                            onChange={handleInputChange}
                        />
                        <TextField
                            label="Cuenta Bancaria"
                            style={{ marginBottom: '10px', paddingTop: '0' }} 
                            variant="outlined"
                            fullWidth  
                            name="prov_cta_bancaria"
                            value={selectProveedor.prov_cta_bancaria}
                            onChange={handleInputChange}
                        />
                        <TextField
                            label="Tarjeta Ecopay"
                            style={{ marginBottom: '10px', paddingTop: '0' }} 
                            variant="outlined"
                            fullWidth  
                            name="prov_num_tarjeta_ecopay"
                            value={selectProveedor.prov_num_tarjeta_ecopay}
                            onChange={handleInputChange}
                        />
                        <TextField
                            label="Operación Anual"
                            style={{ marginBottom: '10px', paddingTop: '0' }} 
                            variant="outlined"
                            fullWidth  
                            name="prov_total_op_anual"
                            value={selectProveedor.prov_total_op_anual}
                            onChange={handleInputChange}
                        />
                        <TextField
                            label="Matricula"
                            style={{ marginBottom: '10px', paddingTop: '0' }} 
                            variant="outlined"
                            fullWidth  
                            name="prov_matricula"
                            value={selectProveedor.prov_matricula}
                            onChange={handleInputChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>setOpenDialog(false)} color="error">Cancelar </Button>
                        <Button onClick={handleSubmitEdit} color="primary">Guardar Cambios </Button>
                    </DialogActions>
                </Dialog>
            </div>
        
        </>
    )
}

export default UpdateProveedores;