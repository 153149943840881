import axios from 'axios';
import API_URL from  './config';

export const getSeries = async () => {

    try{
        const response = await axios.get(`${API_URL}/serie/getSerie`);        
        return response.data

    } catch(error){
        console.error('Error obteniendo tipos de serie', error );
        return [];
    }
}

export const getSeriesByIdTipo = async (idTipo) => {
    try {
        if(!idTipo){
            return;
        }else {
            const response = await axios.get(`${API_URL}/serie/getSeriebyTipo/${idTipo}`);
            return response.data;
        }

    } catch(error){
        console.error('Error obteniendo los consecutivos por tipo', error );
        return [];
    }
}

export const addSeriesConsecutivo = async(idSerie, consecutivo) => {

    try {
        const payload = {
            id_serie: idSerie,
            ultimo_consecutivo: consecutivo
        };

        const response = await axios.post(`${API_URL}/serie/addSerie`, payload);
        return response.data;

    } catch (error) {
        console.error('Error agregando el consecutivo', error);
        return { success: false, message: 'Error agregando el consecutivo' };
    }
}


