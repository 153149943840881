import React, { useEffect } from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const PdfGenerator = ({ data }) => {
    useEffect(() => {
        if (!data) return;
        //console.log("Contenido de rows:", data.rows);

        const formatDate = (dateString) => {
            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
        };

        const formattedDate = formatDate(data.currentDate);

        const doc = new jsPDF();

        // Cuadro principal izquierdo
        //doc.setLineWidth(0.5);
        doc.rect(10, 10, 90, 35); // Coordenadas x, y, ancho, alto
        doc.setFontSize(8);
        doc.text("RECUP.INDUST.URBIETA, S.L.", 15, 15);
        doc.setFontSize(8);
        doc.text("POL.IND.ARETA - CL.IRUMUGA 11", 15, 20);
        doc.text("31620 HUARTE NAVARRA", 15, 25);
        doc.text("B31663099", 15, 30);
        doc.text("948 331 935", 15, 35);
        doc.text("administracion@urbieta.org", 15, 40);

        // Cuadro principal derecho
        //doc.rect(110, 10, 90, 35); // Coordenadas x, y, ancho, alto
        doc.setFontSize(8);
        doc.setTextColor(0, 102, 0); // Color verde
        doc.text("PROVEEDOR CÓD.:", 115, 15);
        doc.text(data.codCliente?.toString() || "-", 160, 15); // Código del proveedor
        doc.setTextColor(0, 0, 0); // Texto negro
        doc.text(data.proveedor || "-", 115, 20); // Nombre del proveedor
        doc.text("P.I. ARBIDE 1, NAVE NUMERO 11.", 115, 25);
        doc.text("31110 NOAIN (VALLE DE ELORZ)/NC NAVARRA", 115, 30);

        // Tabla de encabezado
        doc.setFillColor(26, 189, 156);  // Verde claro
        doc.rect(10, 50, 190, 8, "F"); // Fondo de encabezado
        doc.setTextColor(255, 255, 255); // Negro
        doc.setFontSize(8);
        
        doc.text("DOCUMENTO", 12, 55); // Alineado a la izquierda dentro del área
        doc.text("NÚMERO", 40, 55); // Centrando dentro del área
        doc.text("PÁGINA", 60, 55); // Más a la derecha, pero dentro de la mitad
        doc.text("FECHA", 80, 55); // Cerca del borde del rectángulo
        if(data.matricula){
            doc.text("MATRÍCULA", 105, 55); // Cerca del borde del rectángulo
        }else {
            doc.text("", 105, 55); // Cerca del borde del rectángulo
        }
        doc.text("ENTRADA", 135, 55); // Cerca del borde del rectángulo
        doc.text("SALIDA", 160, 55); // Cerca del borde del rectángulo
        doc.text("PESO FINAL", 180, 55); // Cerca del borde del rectángulo

        // Datos del encabezado

        doc.setFontSize(8);
        doc.setTextColor(0, 0, 0); // Negro
        doc.text("Entrada", 12, 63);
        doc.text(data.consecutivo?.toString() || "-", 40, 63); // Número del documento
        doc.text("1", 60, 63); // Página fija
        doc.text(formattedDate, 80, 63);
        if(data.matricula){
            doc.text(data.matricula, 105, 63);
        }else {
            doc.text('', 105, 63);
        }      
        doc.text(data.pesoCamionEntrada+ 'Kg', 135, 63); 
        doc.text(data.pesoCamionSalida + 'Kg', 160, 63); 
        doc.text(data.pesoFinal.toString()+ 'Kg' || '', 180, 63); 
        doc.line(10, 65, 200, 65); // Línea horizontal bajo el encabezado


        // Fondo verde claro para los encabezados
        doc.setFillColor(26, 189, 156); // Verde claro
        doc.rect(10, 70, 190, 8, "F"); // Rectángulo verde (ancho completo)

        // Encabezados de la nueva fila
        doc.setFontSize(8);
        doc.setTextColor(255, 255, 255); // Texto negro
        doc.text("N.I.F.", 12, 75); // Posiciona "N.I.F." cerca del borde izquierdo
        doc.text("MÉTODO DE PAGO", 60, 75); // Centra "FORMA DE PAGO" en su área
        if (data.metodoPago?.toLowerCase() === "ecopay card") {
            doc.text("NÚMERO TARJETA ",100, 75);
        }else if (data.metodoPago?.toLowerCase()  === "transferencia bancaria"){
            doc.text("NÚMERO CUENTA ",100, 75);
        } else {
            doc.text("  ", 100, 85); // Mostrar un guion si no aplica
        }
        
        // Líneas de borde inferior (rectángulos blancos con borde negro)
        doc.setDrawColor(0, 0, 0); // Borde negro
        doc.rect(10, 78, 190, 10); // Contenedor principal para "N.I.F." y "FORMA DE PAGO"

        // Datos correspondientes
        doc.setFontSize(8);
        doc.setTextColor(0, 0, 0);
        doc.text(data.nifCliente, 12, 85); // N.I.F. dentro de su celda
        doc.text(data.metodoPago || '', 60, 85); // FORMA DE PAGO está vacío por ahora

        if (data.metodoPago?.toLowerCase() === "ecopay card") {
            doc.text(data.numTarjeta || "", 100, 85); // Mostrar el número de tarjeta
        }else if (data.metodoPago?.toLowerCase()  === "transferencia bancaria"){
            doc.text(data.numCuenta || "", 100, 85); // Mostrar el número de tarjeta
        } else {
            doc.text("  ", 100, 85); // Mostrar un guion si no aplica
        }



    //INICIO TABLAS
    // Tabla de productos
    const tableBody = data.rows.map((row) => [
        row.articulo || "-", // Validación para ARTÍCULO
        row.descripcion || "-", // Validación para DESCRIPCIÓN
        row.cantidad || "0", // Validación para CANTIDAD
        row.precio.toFixed(2) + ' €' || "0.00", 
        row.subtotal.toFixed(2) + ' €'|| "0.00", // precio por cantidad
        row.impuesto + '%' || "0%", // Validación para DTO.
        //row.impuesto + '%' || "0%", // Validación para DTO.
        typeof row.total === "number" ? row.total.toFixed(2) + ' €' : "0.00", // Validación para TOTAL
    ]);

    // Generar la primera tabla (productos)
    doc.autoTable({
        head: [["ARTÍCULO", "DESCRIPCIÓN", "CANTIDAD", "PRECIO", "SUBTOTAL", "IVA %", "TOTAL"]],
        body: tableBody,
        startY: 90, // Ajusta la posición inicial en el eje Y
        theme: "grid",
        styles: { fontSize: 8 },
        columnStyles: {
            0: { cellWidth: 20 }, // ARTÍCULO
            1: { cellWidth: 55 }, // DESCRIPCIÓN
            2: { cellWidth: 20, halign: "right" }, // CANTIDAD
            3: { cellWidth: 20, halign: "right" }, // PRECIO
            4: { cellWidth: 25, halign: "right" }, // SUBTOTAL
            5: { cellWidth: 20, halign: "right" }, // DTO
            6: { cellWidth: 30, halign: "right" }, // TOTAL
        },
        margin: { left: 10, right: 10 },
        tableWidth: 190, // Ancho total de la tabla (ajustado a página estándar A4)
        didDrawCell: (data) => {
            // Aplica fondo verde a la fila de encabezado
            if (data.row.index === 0) {
                doc.setFillColor(184, 204, 184); // Verde claro
            }
        },
    });

    // Calcular la altura de la primera tabla para determinar el inicio de la segunda
    const firstTableHeight = doc.lastAutoTable.finalY; // Altura de la primera tabla

    // Datos adicionales para la segunda tabla (impuestos)
    const footerData = [
        ["21,00", "", "", ""],
        ["10,00",  "", "", ""],
        ["4,00", "Suma de subtotales ", "Suma de difencias del iva "],
        ["I.S.P", "", "", ""]
    ];

    const resultadosPorImpuesto = data.rows.reduce((acumulador, item) => {
        const impuesto = parseFloat(item.impuesto) || 0;
        if (!acumulador[impuesto]) {
            acumulador[impuesto] = { subtotal: 0, impuestoCalculado: 0 };
        }
        // Acumular subtotales y calcular impuestos
        acumulador[impuesto].subtotal += item.subtotal;
        acumulador[impuesto].impuestoCalculado += item.total - item.subtotal;
        return acumulador;
    }, {});

      // Mapear los resultados en footerData
    footerData.forEach(row => {
        const tasa = parseFloat(row[0].replace(",", ".").replace("%", "")) || 0; // Convertir el string "21,00" a 21.00
        const datos = resultadosPorImpuesto[tasa] || { subtotal: 0, impuestoCalculado: 0 };
        row[1] = datos.subtotal.toFixed(2)+' €';
        row[2] = datos.impuestoCalculado.toFixed(2)+' €';
    });

    // Generar la segunda tabla (impuestos)
    doc.autoTable({
        head: [
            ["TIPO", "BASE", "I.V.A."]
        ],
        body: footerData, // Datos de la segunda tabla
        startY: firstTableHeight + 5, // Ajusta la posición Y después de la primera tabla
        theme: "grid",
        styles: { fontSize: 8 },
        columnStyles: {
            0: { cellWidth: 30 }, // TIPO
            1: { cellWidth: 80, halign: "right" }, // BASE
            2: { cellWidth: 80, halign: "right" }, // I.V.A.
        },
        margin: { left: 10, right: 10 },
        tableWidth: 190,
        didDrawCell: (data) => {
            // Aplica fondo verde a la fila de encabezado
            if (data.row.index === 0) {
                doc.setFillColor(184, 204, 184); // Verde claro
            }
        },
        headStyles: {
            halign: "center", // Alinear los títulos al centro
            fontSize: 8, // Tamaño del texto
           //fontStyle: "bold"  Negrita en los títulos
        }
    });





    ///FIN TABLAS








        // Totales
        const finalY = doc.lastAutoTable.finalY + 20;
        doc.text("Operación sujeta a Inversión del Sujeto Pasivo conforme a la LF19/1992 art.31.1.2º.c" ,50, (finalY-15) )
        doc.text("TOTAL:",150, finalY);
        doc.text(typeof data.totalSum === "number" ? data.totalSum.toFixed(2)+ ' €' : "0.00", 190, finalY, { align: "right" });
        // Abrir el PDF en una nueva ventana
        window.open(doc.output("bloburl"), "_blank");
    }, [data]);

    return null; // Este componente no renderiza nada visible
};

export default PdfGenerator;
